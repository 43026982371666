.mainSection {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  max-width: 1200px;

  pre {
    overflow-x: scroll;
  }

  .disclaimer {
    background-color: lightgoldenrodyellow;
    padding: 10px;
  }
}
