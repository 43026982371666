.mainSection {
  margin-bottom: 20px;

  .textField {
    text-align: left;
  }

  .skeleton {
    margin-bottom: 20px;
  }

  .pcbaImageSection {
    display: flex;
    justify-content: flex-start;
    .pcbaImageContainer {
      width: 400px;
      height: 400px;
      border: 1px solid black;
      background-color: #ccc;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .firmwareInfo {
    p {
      text-align: left;
    }
    ol {
      text-align: left;
      margin: 0;
      // padding: 0;
      padding-inline-start: 20px;
      ul {
        padding-inline-start: 10px;
      }
    }
  }

  .lastUpdatedGridItem {
    text-align: left;
    .lastUpdatedAlert {
      margin-bottom: 10px;
    }
  }
}
