.mainSection {
  text-align: left;
  margin-bottom: 20px;

  .portSelection {
    margin-bottom: 10px;
    .selectDropdown {
      margin-bottom: 10px;
    }
    .searchPortsButton {
      margin-bottom: 10px;
    }
  }

  .uploadFirmwareButton {
    margin-bottom: 10px;
    .firmwareOptionalMessage {
      padding: 10px;
      background-color: yellow;
    }
    button {
      padding: 10px;
    }

    .alert {
      margin-bottom: 10px;
    }
  }

  .confirmPort {
    margin-bottom: 10px;
  }

  .skipFirmwareButton {
    margin-bottom: 10px;
    .alert {
      margin-bottom: 10px;
    }
    .skipFirmwareButton {
      margin-bottom: 10px;
    }
  }

  .firmwareInfo {
    .alert {
      margin-bottom: 10px;
    }
  }

  .firmwareUploadSuccessFailedAlert {
    margin-bottom: 10px;
  }

  .firmwareFileNamesMessage {
    margin-bottom: 10px;
    h4 {
      margin: 0;
    }
    ol {
      margin: 0;
      li {
        margin: 0;
      }
    }
  }

  .firmwareUploadMessage {
    margin: 8px 0;
    pre {
      background-color: #000;
      color: #fff;
      padding: 8px;
      overflow-x: scroll;
    }
  }

  .firmwareUploadStdout {
    pre {
      background-color: #000;
      color: #fff;
      padding: 8px;
      overflow-x: scroll;
    }
  }

  label {
    display: block;
  }

  select {
    width: 400px;
  }

  .prompt {
    margin-bottom: 10px;
  }

  .yesNoButton {
    margin-right: 10px;
  }
}
