.paper {
  min-width: 500px;
  min-height: 400px;
  .mainSection {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    max-width: 1200px;

    h1 {
      text-align: center;
      margin: 0;
    }

    .chip {
      display: flex;
      justify-content: center;
    }

    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
