.mainSection {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1200px;

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }

  .navBtns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .alert {
    margin-bottom: 10px;
  }

  .pcbaImageSection {
    display: flex;
    justify-content: flex-start;
    .pcbaImageContainer {
      width: 400px;
      height: 400px;
      border: 1px solid black;
      background-color: #ccc;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .allStepsCompleted {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}
