.mainSection {
  margin-bottom: 20px;

  .connectToSerialPortButton {
    margin-bottom: 10px;
  }

  .testButton {
    margin-bottom: 10px;
  }
  .submitTestResultButton {
    margin-bottom: 10px;
  }
  .alert {
    margin: 10px 0;
  }
  .testDetails {
    ul {
      list-style: none;
      padding: 0;
    }
  }
  .testRemarksInput {
    margin-bottom: 10px;
  }
  .mcuidShort {
    color: blue;
  }
}
